import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const AboutPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO title="About" keywords={[`blog`, `gatsby`, `javascript`, `react`]} />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <h2 id="clean-minimal-and-deeply-customisable-london-is-a-theme-made-for-people-who-appreciate-simple-lines-">
            A confident, friendly and reliable young person who is fascinated by
            tech, extreme sports & putting smiles on people's faces. Not afraid
            to jump in and assist wherever i'm needed.
          </h2>
          <figure className="kg-card kg-image-card kg-width">
            <Img
              fluid={data.profilepic.childImageSharp.fluid}
              className="kg-image"
            />
            {/* <figcaption>Large imagery is at the heart of this theme</figcaption> */}
          </figure>

          <hr />
          <h2 id="blockquote">
            <strong>KNOWLEDGE</strong>
          </h2>
          <blockquote>
            <ul>
              <li>HTML5</li>
              <li>CSS</li>
              <li>JAVASCRIPT</li>
              <li>VANILLA</li>
              <li>REACT</li>
              <li>VUEJS</li>
              <li>WORDPRESS</li>
              <li>GATSBY</li>
              <li>FIREBASE</li>
              <li>NETLIFY</li>
            </ul>
          </blockquote>
          <hr />
          <h2>
            I am taking new projects. <br />
            Waiting to hearing about new projects, so if you'd like to chat
            please get in touch.
          </h2>
          <h2>
            <a href="mailto: tuccialessandrox@gmail.com">Email</a>
          </h2>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    profilepic: file(relativePath: { eq: "profilepic.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
)
